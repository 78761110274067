var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('div',{staticClass:"container"},[_c('a-form',{attrs:{"label-col":{ span: 8 },"wrapper-col":{ span: 12 },"colon":false,"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{staticClass:"form-row"},[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'name',
                { rules: [{ required: true, message: '请输入' }] },
              ]),expression:"[\n                'name',\n                { rules: [{ required: true, message: '请输入' }] },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"键值"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'value',
                { rules: [{ required: true, message: '请输入' }] },
              ]),expression:"[\n                'value',\n                { rules: [{ required: true, message: '请输入' }] },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"图标"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['icon']),expression:"['icon']"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"排序"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sort']),expression:"['sort']"}],staticStyle:{"width":"100%"},attrs:{"min":0}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"所属字典"}},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'pid',
                { rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'pid',\n                { rules: [{ required: true, message: '请选择！' }] },\n              ]"}],attrs:{"placeholder":"","changeOnSelect":"","options":_vm.dataDictTree,"fieldNames":{
                label: 'name',
                value: 'key',
                children: 'children',
              }}})],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{attrs:{"htmlType":"submit","type":"primary"}},[_vm._v("保存")]),_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }